import React from "react"
import Layout from "../layouts/index"
import SEO from "../components/seo"

const ContactFr = () => (
  <Layout cssClass="contact blog-style">
    <SEO title="Contact" />
    <div className="main-content">
      <h1>Contact</h1>
    </div>
  </Layout>
)

export default ContactFr
